import React from 'react'
import PropTypes from 'prop-types'

import { _ } from 'Services/I18n'

import { GroupedServices } from 'Components/Blocks'
import { Dash } from 'Components/UI'

import { useBaseTheme } from 'Hooks'
import {
  Container,
  Content,
  StyledTitle,
  GroupedServicesTitleText,
} from './styles'

function StickyServices({ services, showTitle, ...rest }) {
  const { bookingPanelTitle, bookingPanelCopy } = useBaseTheme()

  return (
    <Container {...rest}>
      <Content>
        {showTitle && (
          <>
            <StyledTitle>
              {bookingPanelTitle || _('common.bookNow')}
            </StyledTitle>
            <Dash mb={3} mt={2} />
          </>
        )}
        {bookingPanelCopy && (
          <GroupedServicesTitleText>
            {bookingPanelCopy}
          </GroupedServicesTitleText>
        )}
      </Content>
      <GroupedServices services={services} />
    </Container>
  )
}

StickyServices.defaultProps = {
  services: null,
  showTitle: true,
}

StickyServices.propTypes = {
  services: PropTypes.array,
  showTitle: PropTypes.bool,
}

export default StickyServices
