import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import FsLightbox from 'fslightbox-react'
import map from 'lodash/map'

import { useBaseTheme, useHydrated } from 'Hooks'

import { WishlistToggle } from 'Components/Blocks'

import { MERCHANT_PAGE_LAYOUT } from 'Constants/ids'

import MerchantSlider from '../MerchantSlider'

import {
  ImageBg,
  Container,
  SecondaryImages,
  SecondaryImage,
  Wrapper,
  WishlistToggleContainer,
} from './styles'

function MerchantImages({ images, ...rest }) {
  if (!images?.length) {
    return null
  }

  const hydrated = useHydrated()
  const { isLargeScreen, merchantPageLayout } = useBaseTheme()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalImageIndex, setModalImageIndex] = useState(0)

  const isLayout2 = merchantPageLayout === MERCHANT_PAGE_LAYOUT.LAYOUT_2
  const [showSlider, setShowSlider] = useState(!isLayout2 || !isLargeScreen)

  useEffect(() => {
    setShowSlider(!isLayout2 || !isLargeScreen)
  }, [isLargeScreen])

  const handleImageClick = useCallback(index => {
    setModalImageIndex(index)
    setModalOpen(!isModalOpen)
  })

  // Show slider for SSR due to rendering issues
  if (showSlider || !hydrated) {
    return (
      <MerchantSlider
        color={rest.color}
        images={images}
        mb={[3, 3, 0]}
        mt={[3, 3, 4]}
        token={rest.token}
      />
    )
  }

  const mainImage = images[0]

  const renderSecondaryImages = () => {
    const imageCount = images.length

    if (imageCount === 1) {
      return null
    }

    if (imageCount === 2) {
      return (
        <Container onClick={() => handleImageClick(1)}>
          <ImageBg src={images[1]} />
        </Container>
      )
    }

    if (imageCount === 3) {
      return (
        <Container flexDirection="column">
          {map(images.slice(1, imageCount), (image, index) => (
            <SecondaryImage
              key={index}
              onClick={() => handleImageClick(index + 1)}
            >
              <ImageBg src={image} />
            </SecondaryImage>
          ))}
        </Container>
      )
    }

    return (
      <Container>
        <SecondaryImages>
          {map(images, (image, index) => (
            <SecondaryImage key={index} onClick={() => handleImageClick(index)}>
              <ImageBg src={image} />
            </SecondaryImage>
          ))}
        </SecondaryImages>
      </Container>
    )
  }

  return (
    <>
      <Wrapper {...rest}>
        <Container onClick={() => handleImageClick(0)}>
          <WishlistToggleContainer>
            <WishlistToggle token={rest.token} />
          </WishlistToggleContainer>
          <ImageBg src={mainImage} />
        </Container>
        {renderSecondaryImages()}
      </Wrapper>

      <FsLightbox
        exitFullscreenOnClose
        sourceIndex={modalImageIndex}
        sources={images}
        toggler={isModalOpen}
        type="image"
      />
    </>
  )
}

MerchantImages.defaultProps = {
  images: [],
}

MerchantImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
}

export default MerchantImages
