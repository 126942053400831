import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import { Helmet } from 'react-helmet'

import get from 'lodash/get'
import map from 'lodash/map'
import compact from 'lodash/compact'
import toArray from 'lodash/toArray'

import jwt from 'jsonwebtoken'

import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'

import { _ } from 'Services/I18n'

import { withAppContext } from 'Services/Context'
import { processBucketForSearchPathParams } from 'Services/Utils/merchants'
import * as Utils from 'Services/Utils'

import { PUBLIC_PATHS } from 'Constants/paths'
import StorageKeys from 'Constants/storageKeys'
import {
  MARKETPLACE_TYPE,
  MARKETPLACE_SERVICE_TYPE,
  MERCHANT_CONTENT_TAB,
  MERCHANT_PAGE_LAYOUT,
} from 'Constants/ids'

import { IS_PRODUCTION } from 'Config/app'
import { Breadcrumbs, ErrorContent, RatingStars } from 'Components/Blocks'
import { Loader, Tooltip } from 'Components/UI'

import { loadMerchant } from 'Store/Actions/marketplace'

import MerchantImages from './MerchantImages'
import StickyServices from './StickyServices'
import Services from './Services'

import {
  About,
  AdditionalInfo,
  Awards,
  LocationWellness,
  Qualifications,
  Features,
  Ratings,
  Widget,
  CategoryPills,
  ContentTabs,
} from './Content'

import {
  WrapperContainer,
  Container,
  Responsive,
  Content,
  Row,
  BackIcon,
  BackButton,
  MainContent,
  Top,
  TopSub,
  StyledTitle,
  SubTitle,
  MapIcon,
  MerchantLogo,
} from './styles'

class Merchant extends PureComponent {
  static fetchData(store, match, query) {
    const { dispatch } = store
    const token = get(match, 'params.id')

    // If in preview mode we don't want to use the API cache
    const useCache = !query.preview

    return dispatch(loadMerchant({ token, cache: useCache, ssr: true }))
  }

  state = {
    activeMobileContentTab: MERCHANT_CONTENT_TAB.about,
  }

  addressRef = createRef()

  reviewWidgetRef = createRef()

  async componentDidMount() {
    const {
      baseTheme,
      match,
      merchant,
      onLoadMerchant,
      authentication,
      history,
      searchDate,
      servicesAvailability,
      ssrMerchantPreloaded,
      onWidgetAuthenticate,
    } = this.props

    const marketplaceType = get(baseTheme, 'marketplace_type')
    const token = match.params.id

    // If in preview mode we don't want to use the API cache
    const useCache = !get(history, 'location.search', '').includes(
      'preview=true',
    )

    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (get(merchant, 'token') !== token && !ssrMerchantPreloaded) {
      onLoadMerchant({ token, cache: useCache }).then(() => {
        // Load service availability for merchant
        this.handleLoadServiceAvailability(searchDate, servicesAvailability)
      })
    } else {
      // Merchant data already exists in store, check for service availability
      this.handleLoadServiceAvailability(searchDate, servicesAvailability)
    }

    if (marketplaceType === MARKETPLACE_TYPE.wellness) {
      return
    }

    if (!authentication.require_auth || !IS_PRODUCTION) {
      return
    }

    const appToken = localStorage.getItem(StorageKeys.WIDGET_TOKEN)
    const isExpired = this.isExpired(appToken)

    if (isExpired) {
      localStorage.removeItem(StorageKeys.WIDGET_TOKEN)
      history.push(PUBLIC_PATHS.REDIRECTOR())
      return
    }

    const result = await onWidgetAuthenticate(appToken)
    if (!get(result, 'data.success')) {
      history.push(PUBLIC_PATHS.REDIRECTOR())
      localStorage.removeItem(StorageKeys.WIDGET_TOKEN)
    }
  }

  isExpired = token => {
    const result = jwt.decode(token)
    const now = parseInt(Date.now() / 1000, 10)
    return +get(result, 'exp', 0) < now
  }

  redirectToUrl = url => {
    window.location.replace(url)
  }

  get = type => {
    const { merchant } = this.props
    return get(merchant, type)
  }

  getArray = type => {
    const { merchant } = this.props

    return toArray(get(merchant, type))
  }

  handleSetSearchValue = value => () => {
    const { onSetSearchValue } = this.props

    onSetSearchValue({
      label: value.bucket_name,
      type: value.bucket_type,
      value,
    })
  }

  getBreadCrumbs = () => {
    const { categoryValue } = this.props
    const { categoryId, categoryFullName } =
      Utils.getCategoryProps(categoryValue)

    return map(this.get('geographical_buckets'), (bucket, bucketType) => {
      const queryParams = {
        ...processBucketForSearchPathParams(
          { ...bucket, bucket_type: bucketType },
          1,
        ),
        categoryId,
        categoryFullName,
      }

      const link = (
        <NavLink
          exact
          key={queryParams.bucketId}
          to={PUBLIC_PATHS.SEARCH_QUERY(queryParams)}
          onClick={() => {
            this.handleSetSearchValue({
              ...bucket,
              bucket_type: bucketType,
            })
          }}
        >
          {queryParams.bucketName}
        </NavLink>
      )

      return {
        id: queryParams.bucketId,
        element: bucketType !== 'state' ? link : queryParams.bucketName,
      }
    })
  }

  handleGoBack = () => {
    const { history } = this.props

    // This would ensure that on goBack(), the previous page from browser history would load
    history.replace(get(history, 'location.state.prevPath', '/'))
    history.goBack()
  }

  handleReview = () => {
    if (this.get('reviews.total') < 1) {
      return
    }

    this.setState({ activeMobileContentTab: MERCHANT_CONTENT_TAB.reviews })

    if (this.reviewWidgetRef.current) {
      this.reviewWidgetRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  handleLocation = () => {
    this.setState({ activeMobileContentTab: MERCHANT_CONTENT_TAB.about })

    if (this.addressRef.current) {
      this.addressRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  handleLoadServiceAvailability = (searchDate, servicesAvailability) => {
    const { onLoadServicesAvailability } = this.props

    if (!searchDate) {
      return
    }

    const date = searchDate.format('YYYY-MM-DD')

    // If the date has not changed, filter out service IDs
    // from the array that we already have availability for
    const services = map(this.getArray('services'), 'id')
      .filter(serviceId => !get(servicesAvailability, serviceId))
      .slice(0, 30)

    if (!services.length) {
      return
    }

    onLoadServicesAvailability({
      date,
      services,
    })
  }

  handleMobileContentTabChange = tabIndex => {
    this.setState({ activeMobileContentTab: tabIndex })
  }

  render() {
    const { activeMobileContentTab } = this.state

    const {
      baseTheme,
      breakpoint,
      config,
      history,
      theme: { breakpointNames },
      merchant,
      merchantError,
      isLoadingMerchant,
    } = this.props

    if (isLoadingMerchant) {
      return <Loader color={get(baseTheme, 'colors.secondary_background')} />
    }

    if (merchantError) {
      return <ErrorContent error={merchantError} onBack={this.handleGoBack} />
    }

    const merchantPageLayout = get(baseTheme, 'merchant_page_layout', 0)
    const buckets = this.get('geographical_buckets')
    const state = get(buckets, 'state.bucket_name')
    const suburb = get(buckets, 'suburb.bucket_name')
    const addressLocation = buckets && suburb && state && `${suburb}, ${state}`
    const primaryBackground = get(baseTheme, 'colors.primary_background')
    const primaryColor = get(baseTheme, 'colors.primary_text')
    const color = get(baseTheme, 'colors.secondary_background')
    const roundedButton = get(baseTheme, 'colors.rounded_border')
    const logoUrl = get(merchant, 'logo')
    const showLogo = get(baseTheme, 'display_business_logo') && logoUrl
    const popupText = get(baseTheme, 'website_popup_msg')
    const isFullWidth = breakpoint === breakpointNames.LARGE
    const isLayout2 = merchantPageLayout === MERCHANT_PAGE_LAYOUT.LAYOUT_2
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')
    const bookNowTitle = get(
      baseTheme,
      'booking_panel_title',
      _('common.bookNow'),
    )

    const renderAboutContent = () => {
      return (
        <>
          <About mt="32px" text={this.get('description')} />

          <CategoryPills mt="16px" services={this.getArray('services')} />
        </>
      )
    }

    const renderMobileContent = () => {
      const isReviewsVisible =
        this.get('reviews.total') > 0 &&
        activeMobileContentTab === MERCHANT_CONTENT_TAB.reviews

      const isAboutContentVisible =
        activeMobileContentTab === MERCHANT_CONTENT_TAB.about

      const isServicesVisible =
        this.get('services') &&
        activeMobileContentTab === MERCHANT_CONTENT_TAB.book

      const mobileContentTabs = [
        {
          id: MERCHANT_CONTENT_TAB.about,
          label: _('common.aboutUs'),
          visible: true,
        },
        {
          id: MERCHANT_CONTENT_TAB.book,
          label: bookNowTitle,
          visible: this.get('services'),
        },
        {
          id: MERCHANT_CONTENT_TAB.reviews,
          label: _('common.reviews'),
          visible: this.get('reviews.total') > 0,
        },
      ]

      const renderMobileServices = () => {
        return isLayout2 ? (
          <Services services={this.getArray('services')} title="" top="32px" />
        ) : (
          <StickyServices
            mt="32px"
            services={this.getArray('services')}
            showTitle={false}
            width="100% !important"
          />
        )
      }

      return (
        <>
          <ContentTabs
            activeTab={activeMobileContentTab}
            tabs={mobileContentTabs}
            onTabChange={this.handleMobileContentTabChange}
          />

          {isServicesVisible && renderMobileServices()}

          {isAboutContentVisible && (
            <>
              {renderAboutContent()}

              <Awards awards={this.get('awards')} color={color} mt="32px" />

              <Qualifications
                color={color}
                mt="32px"
                qualifications={this.get('qualifications')}
              />

              <AdditionalInfo
                baseTheme={baseTheme}
                filters={this.get('custom_filters')}
                mt="32px"
              />

              <LocationWellness
                addressRef={this.addressRef}
                color={color}
                marketplaceServiceType={marketplaceServiceType}
                merchant={merchant}
                mt="32px"
                popupText={popupText}
                primaryBackground={primaryBackground}
                primaryColor={primaryColor}
                roundedButton={roundedButton}
                website={this.get('website')}
              />

              <Features
                color={color}
                features={this.getArray('features')}
                licences={this.getArray('license_type')}
                mt="32px"
              />

              <Ratings
                color={color}
                mt="32px"
                ratings={this.getArray('ratings')}
              />
            </>
          )}

          {isReviewsVisible && (
            <Widget
              ref={this.reviewWidgetRef}
              widgetUrl={get(merchant, ['reviews', 'widgetUrl'])}
            />
          )}
        </>
      )
    }

    const renderDesktopAltContent = () => {
      return (
        <>
          {renderAboutContent()}

          <Awards awards={this.get('awards')} color={color} mt="32px" />

          <Qualifications
            color={color}
            mt="32px"
            qualifications={this.get('qualifications')}
          />

          <AdditionalInfo
            baseTheme={baseTheme}
            filters={this.get('custom_filters')}
            mt="32px"
          />

          <LocationWellness
            addressRef={this.addressRef}
            color={color}
            marketplaceServiceType={marketplaceServiceType}
            merchant={merchant}
            mt="32px"
            popupText={popupText}
            primaryBackground={primaryBackground}
            primaryColor={primaryColor}
            roundedButton={roundedButton}
            website={this.get('website')}
          />

          <Features
            color={color}
            features={this.getArray('features')}
            licences={this.getArray('license_type')}
            mt="32px"
          />

          <Ratings color={color} mt="32px" ratings={this.getArray('ratings')} />

          {this.get('reviews.total') > 0 &&
            get(merchant, 'reviews.widgetUrl') && (
              <Widget
                mt="32px"
                ref={this.reviewWidgetRef}
                widgetUrl={get(merchant, ['reviews', 'widgetUrl'])}
              />
            )}
        </>
      )
    }

    return (
      <WrapperContainer>
        <Helmet>
          <meta content={this.get('name')} property="og:title" />
          <meta content={this.get('summary')} property="og:description" />
          <meta
            content={this.get('images.medium.main_url')}
            property="og:image"
          />
          <meta
            content={`https://${get(config, 'host')}${get(
              history,
              'location.pathname',
            )}`}
            property="og:url"
          />
          <meta content="website" property="og:type" />
        </Helmet>
        <Container fullwidth={isLayout2 ? 1 : 0}>
          <Responsive>
            <Row>
              <BackButton color={color} onClick={this.handleGoBack}>
                <BackIcon />
                {_('action.back')}
              </BackButton>
              {marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online && (
                <Breadcrumbs links={this.getBreadCrumbs()} />
              )}
            </Row>
            <Content>
              <MainContent>
                <Top>
                  <Tooltip>
                    <StyledTitle>{this.get('name')}</StyledTitle>
                    <TopSub>
                      {addressLocation ? (
                        <SubTitle mt={3}>
                          <MapIcon
                            color={color}
                            onClick={this.handleLocation}
                          />
                          {addressLocation}
                        </SubTitle>
                      ) : null}

                      <RatingStars
                        mt="10px"
                        pt={1}
                        reviews={this.get('reviews')}
                        size={14}
                        onClick={this.handleReview}
                      />
                    </TopSub>
                  </Tooltip>
                  {showLogo && <MerchantLogo src={logoUrl} />}
                </Top>

                <MerchantImages
                  color={color}
                  images={compact(this.getArray('images.original'))}
                  mb={[3, 3, 0]}
                  mt={[3, 3, 4]}
                  token={get(merchant, 'token')}
                />

                {!isLayout2 && isFullWidth && renderDesktopAltContent()}

                {isLayout2 && isFullWidth && renderAboutContent()}

                {!isFullWidth && renderMobileContent()}
              </MainContent>

              {isLayout2 && isFullWidth && (
                <>
                  <Services
                    services={this.getArray('services')}
                    title={bookNowTitle}
                  />

                  <MainContent mt={4}>
                    <Awards awards={this.get('awards')} color={color} />

                    <Qualifications
                      color={color}
                      mt="32px"
                      qualifications={this.get('qualifications')}
                    />

                    <AdditionalInfo
                      baseTheme={baseTheme}
                      filters={this.get('custom_filters')}
                    />
                  </MainContent>

                  <MainContent mt={4}>
                    <LocationWellness
                      addressRef={this.addressRef}
                      color={color}
                      marketplaceServiceType={marketplaceServiceType}
                      merchant={merchant}
                      popupText={popupText}
                      primaryBackground={primaryBackground}
                      primaryColor={primaryColor}
                      roundedButton={roundedButton}
                      website={this.get('website')}
                    />
                  </MainContent>

                  {this.get('reviews.total') > 0 &&
                    get(merchant, 'reviews.widgetUrl') && (
                      <MainContent mt={4}>
                        <Widget
                          ref={this.reviewWidgetRef}
                          widgetUrl={get(merchant, ['reviews', 'widgetUrl'])}
                        />
                      </MainContent>
                    )}
                </>
              )}
            </Content>
          </Responsive>
        </Container>

        {!isLayout2 && (
          <StickyServices
            display={[
              'none !important',
              'none !important',
              'none !important',
              'block !important',
            ]}
            mt="68px"
            services={this.getArray('services')}
          />
        )}
      </WrapperContainer>
    )
  }
}

Merchant.defaultProps = {
  categoryValue: null,
  merchant: null,
  merchantError: null,
  searchDate: null,
  servicesAvailability: null,
  ssrMerchantPreloaded: false,
}

Merchant.propTypes = {
  authentication: PropTypes.object.isRequired,
  baseTheme: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  categoryValue: PropTypes.object,
  config: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isLoadingMerchant: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  merchant: PropTypes.object,
  merchantError: PropTypes.object,
  searchDate: momentPropTypes.momentObj,
  servicesAvailability: PropTypes.object,
  ssrMerchantPreloaded: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  onLoadMerchant: PropTypes.func.isRequired,
  onLoadServicesAvailability: PropTypes.func.isRequired,
  onSetSearchValue: PropTypes.func.isRequired,
  onWidgetAuthenticate: PropTypes.func.isRequired,
}

export default withRouter(withAppContext(Merchant))
