import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import { WishlistToggle } from 'Components/Blocks'

import {
  Container,
  StyledSlider,
  LeftArrow,
  RightArrow,
  PrevArrow,
  NextArrow,
  Image,
  ImageHolder,
  WishlistToggleContainer,
} from './styles'

class MerchantSlider extends PureComponent {
  state = {
    width: 0,
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const width = document.documentElement.clientWidth
    this.setState({ width })
  }

  render() {
    const { width } = this.state
    const { color, images, ...rest } = this.props

    const settings = {
      autoplay: false,
      autoplaySpeed: 8000,
      dots: false,
      dotsClass: 'slick-dots slick-thumb',
      arrows: width > 760,
      nextArrow: (
        <NextArrow color={color}>
          <RightArrow />
        </NextArrow>
      ),
      prevArrow: (
        <PrevArrow color={color}>
          <LeftArrow />
        </PrevArrow>
      ),
    }

    const data = map(images, (src, id) => ({ id, src }))

    const merchantToken = rest.token

    return (
      <Container {...rest}>
        <WishlistToggleContainer>
          <WishlistToggle token={merchantToken} />
        </WishlistToggleContainer>
        <StyledSlider color={color} {...settings}>
          {map(data, ({ id, src }) => (
            <ImageHolder key={id}>
              <Image src={src} />
            </ImageHolder>
          ))}
        </StyledSlider>
      </Container>
    )
  }
}

MerchantSlider.defaultProps = {
  color: '',
  images: [],
}

MerchantSlider.propTypes = {
  color: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
}

export default MerchantSlider
